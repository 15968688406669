const items = [
	{ id: 4, name: '臺北市' },
	{ id: 9, name: '基隆市' },
	{ id: 13, name: '新北市' },
	{ id: 10, name: '宜蘭縣' },
	{ id: 14, name: '新竹市' },
	{ id: 15, name: '新竹縣' },
	{ id: 16, name: '桃園市' },
	{ id: 19, name: '苗栗縣' },
	{ id: 3, name: '臺中市' },
	{ id: 12, name: '彰化縣' },
	{ id: 1, name: '南投縣' },
	{ id: 7, name: '嘉義市' },
	{ id: 8, name: '嘉義縣' },
	{ id: 22, name: '雲林縣' },
	{ id: 5, name: '台南市' },
	{ id: 23, name: '高雄市' },
	{ id: 17, name: '澎湖縣' },
	{ id: 2, name: '南海島' },
	{ id: 11, name: '屏東縣' },
	{ id: 6, name: '臺東縣' },
	{ id: 18, name: '花蓮縣' },
	{ id: 21, name: '金門縣' },
	{ id: 20, name: '連江縣' },
];

export default items;
