import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('notification', {
	state: () => ({
		notifications: [],
		notificationsOfficial: [],
	}),

	getters: {
		unreadCount(state) {
			return (
				state.notifications.filter((z) => z.is_read === false).length +
				state.notificationsOfficial.filter((z) => z.is_read === false).length
			);
		},
	},

	actions: {
		
		async getItems(condition = {}, isFromLayout = false) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'desc',
				is_official: false,
			};

			condition = { ...baseCondition, ...condition };

			try {
				const response = await post('/notification/get_list', condition);
				if (isFromLayout == true) {
					if (condition.is_official) {
						this.notificationsOfficial = response.data.items;
					} else {
						this.notifications = response.data.items;
					}
				}
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async setRead(q) {
			try {
				const response = await post('/notification/set_read', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async setAllRead() {
			try {
				const response = await post('/notification/set_all_read');

				for (const i in this.notifications) {
					const x = this.notifications[i];
					x.is_read = true;
				}
				for (const i in this.notificationsOfficial) {
					const x = this.notificationsOfficial[i];
					x.is_read = true;
				}

				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
