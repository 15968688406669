const items = [
	{
		id: 1,
		name: '起止時間',
	},
	{
		id: 2,
		name: '幾天後失效',
	},
];

export default items;
