const items = [
	{
		id: 1,
		name: '通用優惠碼',
	},
	{
		id: 2,
		name: '隨機優惠碼',
	},
];

export default items;
