import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('cartCalculate', {
	state: () => ({}),
	actions: {
		async calculateCart(product_id, coupon_code = null, quantity = 1, is_trial = false) {
			const condition = {
				product_id,
				coupon_code,
				quantity,
				is_trial,
			};
			try {
				const response = await post('/_cart_calculate/calculate_cart', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
	},
});
