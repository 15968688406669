const items = [
	{
		id: 1,
		name: 'ETF',
	},
	{
		id: 2,
		name: '產業個股',
	},
	{
		id: 3,
		name: '新聞事件',
	},
	{
		id: 4,
		name: '全球總經',
	},
];

export default items;
