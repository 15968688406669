import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('product', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'desc',
				name: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/product/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItem(q) {
			try {
				const response = await post('/product/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async update(q) {
			try {
				const response = await post('/product/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async delete(q) {
			try {
				const response = await post('/product/delete', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to delete', error);
			}
		},
		
		async restore(q) {
			try {
				const response = await post('/product/restore', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to delete', error);
			}
		},

	},
});
