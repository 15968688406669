<template>
	<main>
		<slot></slot>
	</main>
</template>

<script>
export default {
	data() {
		return {};
	},
};
</script>
