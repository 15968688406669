const items = [
	{ id: 1, cityID: 4, name: '中正區', zip: 100 },
	{ id: 2, cityID: 4, name: '大同區', zip: 103 },
	{ id: 3, cityID: 4, name: '中山區', zip: 104 },
	{ id: 4, cityID: 4, name: '松山區', zip: 105 },
	{ id: 5, cityID: 4, name: '大安區', zip: 106 },
	{ id: 6, cityID: 4, name: '萬華區', zip: 108 },
	{ id: 7, cityID: 4, name: '信義區', zip: 110 },
	{ id: 8, cityID: 4, name: '士林區', zip: 111 },
	{ id: 9, cityID: 4, name: '北投區', zip: 112 },
	{ id: 10, cityID: 4, name: '內湖區', zip: 114 },
	{ id: 11, cityID: 4, name: '南港區', zip: 115 },
	{ id: 12, cityID: 4, name: '文山區', zip: 116 },
	{ id: 13, cityID: 9, name: '仁愛區', zip: 200 },
	{ id: 14, cityID: 9, name: '信義區', zip: 201 },
	{ id: 15, cityID: 9, name: '中正區', zip: 202 },
	{ id: 16, cityID: 9, name: '中山區', zip: 203 },
	{ id: 17, cityID: 9, name: '安樂區', zip: 204 },
	{ id: 18, cityID: 9, name: '暖暖區', zip: 205 },
	{ id: 19, cityID: 9, name: '七堵區', zip: 206 },
	{ id: 20, cityID: 13, name: '萬里區', zip: 207 },
	{ id: 21, cityID: 13, name: '金山區', zip: 208 },
	{ id: 22, cityID: 13, name: '板橋區', zip: 220 },
	{ id: 23, cityID: 13, name: '汐止區', zip: 221 },
	{ id: 24, cityID: 13, name: '深坑區', zip: 222 },
	{ id: 25, cityID: 13, name: '石碇區', zip: 223 },
	{ id: 26, cityID: 13, name: '瑞芳區', zip: 224 },
	{ id: 27, cityID: 13, name: '平溪區', zip: 226 },
	{ id: 28, cityID: 13, name: '雙溪區', zip: 227 },
	{ id: 29, cityID: 13, name: '貢寮區', zip: 228 },
	{ id: 30, cityID: 13, name: '新店區', zip: 231 },
	{ id: 31, cityID: 13, name: '坪林區', zip: 232 },
	{ id: 32, cityID: 13, name: '烏來區', zip: 233 },
	{ id: 33, cityID: 13, name: '永和區', zip: 234 },
	{ id: 34, cityID: 13, name: '中和區', zip: 235 },
	{ id: 35, cityID: 13, name: '土城區', zip: 236 },
	{ id: 36, cityID: 13, name: '三峽區', zip: 237 },
	{ id: 37, cityID: 13, name: '樹林區', zip: 238 },
	{ id: 38, cityID: 13, name: '鶯歌區', zip: 239 },
	{ id: 39, cityID: 13, name: '三重區', zip: 241 },
	{ id: 40, cityID: 13, name: '新莊區', zip: 242 },
	{ id: 41, cityID: 13, name: '泰山區', zip: 243 },
	{ id: 42, cityID: 13, name: '林口區', zip: 244 },
	{ id: 43, cityID: 13, name: '蘆洲區', zip: 247 },
	{ id: 44, cityID: 13, name: '五股區', zip: 248 },
	{ id: 45, cityID: 13, name: '八里區', zip: 249 },
	{ id: 46, cityID: 13, name: '淡水區', zip: 251 },
	{ id: 47, cityID: 13, name: '三芝區', zip: 252 },
	{ id: 48, cityID: 13, name: '石門區', zip: 253 },
	{ id: 49, cityID: 10, name: '宜蘭市', zip: 260 },
	{ id: 50, cityID: 10, name: '頭城鎮', zip: 261 },
	{ id: 51, cityID: 10, name: '礁溪鄉', zip: 262 },
	{ id: 52, cityID: 10, name: '壯圍鄉', zip: 263 },
	{ id: 53, cityID: 10, name: '員山鄉', zip: 264 },
	{ id: 54, cityID: 10, name: '羅東鎮', zip: 265 },
	{ id: 55, cityID: 10, name: '三星鄉', zip: 266 },
	{ id: 56, cityID: 10, name: '大同鄉', zip: 267 },
	{ id: 57, cityID: 10, name: '五結鄉', zip: 268 },
	{ id: 58, cityID: 10, name: '冬山鄉', zip: 269 },
	{ id: 59, cityID: 10, name: '蘇澳鎮', zip: 270 },
	{ id: 60, cityID: 10, name: '南澳鄉', zip: 272 },
	{ id: 61, cityID: 10, name: '釣魚台列嶼', zip: 290 },
	{ id: 62, cityID: 15, name: '竹北市', zip: 302 },
	{ id: 63, cityID: 15, name: '湖口鄉', zip: 303 },
	{ id: 64, cityID: 15, name: '新豐鄉', zip: 304 },
	{ id: 65, cityID: 15, name: '新埔鎮', zip: 305 },
	{ id: 66, cityID: 15, name: '關西鎮', zip: 306 },
	{ id: 67, cityID: 15, name: '芎林鄉', zip: 307 },
	{ id: 68, cityID: 15, name: '寶山鄉', zip: 308 },
	{ id: 69, cityID: 15, name: '竹東鎮', zip: 310 },
	{ id: 70, cityID: 15, name: '五峰鄉', zip: 311 },
	{ id: 71, cityID: 15, name: '橫山鄉', zip: 312 },
	{ id: 72, cityID: 15, name: '尖石鄉', zip: 313 },
	{ id: 73, cityID: 15, name: '北埔鄉', zip: 314 },
	{ id: 74, cityID: 15, name: '峨眉鄉', zip: 315 },
	{ id: 75, cityID: 16, name: '中壢區', zip: 320 },
	{ id: 76, cityID: 16, name: '平鎮區', zip: 324 },
	{ id: 77, cityID: 16, name: '龍潭區', zip: 325 },
	{ id: 78, cityID: 16, name: '楊梅區', zip: 326 },
	{ id: 79, cityID: 16, name: '新屋區', zip: 327 },
	{ id: 80, cityID: 16, name: '觀音區', zip: 328 },
	{ id: 81, cityID: 16, name: '桃園區', zip: 330 },
	{ id: 82, cityID: 16, name: '龜山區', zip: 333 },
	{ id: 83, cityID: 16, name: '八德區', zip: 334 },
	{ id: 84, cityID: 16, name: '大溪區', zip: 335 },
	{ id: 85, cityID: 16, name: '復興區', zip: 336 },
	{ id: 86, cityID: 16, name: '大園區', zip: 337 },
	{ id: 87, cityID: 16, name: '蘆竹區', zip: 338 },
	{ id: 88, cityID: 19, name: '竹南鎮', zip: 350 },
	{ id: 89, cityID: 19, name: '頭份鎮', zip: 351 },
	{ id: 89, cityID: 19, name: '頭份市', zip: 351 },
	{ id: 90, cityID: 19, name: '三灣鄉', zip: 352 },
	{ id: 91, cityID: 19, name: '南庄鄉', zip: 353 },
	{ id: 92, cityID: 19, name: '獅潭鄉', zip: 354 },
	{ id: 93, cityID: 19, name: '後龍鎮', zip: 356 },
	{ id: 94, cityID: 19, name: '通霄鎮', zip: 357 },
	{ id: 95, cityID: 19, name: '苑裡鎮', zip: 358 },
	{ id: 96, cityID: 19, name: '苗栗市', zip: 360 },
	{ id: 97, cityID: 19, name: '造橋鄉', zip: 361 },
	{ id: 98, cityID: 19, name: '頭屋鄉', zip: 362 },
	{ id: 99, cityID: 19, name: '公館鄉', zip: 363 },
	{ id: 100, cityID: 19, name: '大湖鄉', zip: 364 },
	{ id: 101, cityID: 19, name: '泰安鄉', zip: 365 },
	{ id: 102, cityID: 19, name: '銅鑼鄉', zip: 366 },
	{ id: 103, cityID: 19, name: '三義鄉', zip: 367 },
	{ id: 104, cityID: 19, name: '西湖鄉', zip: 368 },
	{ id: 105, cityID: 19, name: '卓蘭鎮', zip: 369 },
	{ id: 106, cityID: 3, name: '中區', zip: 400 },
	{ id: 107, cityID: 3, name: '東區', zip: 401 },
	{ id: 108, cityID: 3, name: '南區', zip: 402 },
	{ id: 109, cityID: 3, name: '西區', zip: 403 },
	{ id: 110, cityID: 3, name: '北區', zip: 404 },
	{ id: 111, cityID: 3, name: '北屯區', zip: 406 },
	{ id: 112, cityID: 3, name: '西屯區', zip: 407 },
	{ id: 113, cityID: 3, name: '南屯區', zip: 408 },
	{ id: 114, cityID: 3, name: '太平區', zip: 411 },
	{ id: 115, cityID: 3, name: '大里區', zip: 412 },
	{ id: 116, cityID: 3, name: '霧峰區', zip: 413 },
	{ id: 117, cityID: 3, name: '烏日區', zip: 414 },
	{ id: 118, cityID: 3, name: '豐原區', zip: 420 },
	{ id: 119, cityID: 3, name: '后里區', zip: 421 },
	{ id: 120, cityID: 3, name: '石岡區', zip: 422 },
	{ id: 121, cityID: 3, name: '東勢區', zip: 423 },
	{ id: 122, cityID: 3, name: '和平區', zip: 424 },
	{ id: 123, cityID: 3, name: '新社區', zip: 426 },
	{ id: 124, cityID: 3, name: '潭子區', zip: 427 },
	{ id: 125, cityID: 3, name: '大雅區', zip: 428 },
	{ id: 126, cityID: 3, name: '神岡區', zip: 429 },
	{ id: 127, cityID: 3, name: '大肚區', zip: 432 },
	{ id: 128, cityID: 3, name: '沙鹿區', zip: 433 },
	{ id: 129, cityID: 3, name: '龍井區', zip: 434 },
	{ id: 130, cityID: 3, name: '梧棲區', zip: 435 },
	{ id: 131, cityID: 3, name: '清水區', zip: 436 },
	{ id: 132, cityID: 3, name: '大甲區', zip: 437 },
	{ id: 133, cityID: 3, name: '外埔區', zip: 438 },
	{ id: 134, cityID: 3, name: '大安區', zip: 439 },
	{ id: 135, cityID: 12, name: '彰化市', zip: 500 },
	{ id: 136, cityID: 12, name: '芬園鄉', zip: 502 },
	{ id: 137, cityID: 12, name: '花壇鄉', zip: 503 },
	{ id: 138, cityID: 12, name: '秀水鄉', zip: 504 },
	{ id: 139, cityID: 12, name: '鹿港鎮', zip: 505 },
	{ id: 140, cityID: 12, name: '福興鄉', zip: 506 },
	{ id: 141, cityID: 12, name: '線西鄉', zip: 507 },
	{ id: 142, cityID: 12, name: '和美鎮', zip: 508 },
	{ id: 143, cityID: 12, name: '伸港鄉', zip: 509 },
	{ id: 144, cityID: 12, name: '員林鎮', zip: 510 },
	{ id: 144, cityID: 12, name: '員林市', zip: 510 },
	{ id: 145, cityID: 12, name: '社頭鄉', zip: 511 },
	{ id: 146, cityID: 12, name: '永靖鄉', zip: 512 },
	{ id: 147, cityID: 12, name: '埔心鄉', zip: 513 },
	{ id: 148, cityID: 12, name: '溪湖鎮', zip: 514 },
	{ id: 149, cityID: 12, name: '大村鄉', zip: 515 },
	{ id: 150, cityID: 12, name: '埔鹽鄉', zip: 516 },
	{ id: 151, cityID: 12, name: '田中鎮', zip: 520 },
	{ id: 152, cityID: 12, name: '北斗鎮', zip: 521 },
	{ id: 153, cityID: 12, name: '田尾鄉', zip: 522 },
	{ id: 154, cityID: 12, name: '埤頭鄉', zip: 523 },
	{ id: 155, cityID: 12, name: '溪州鄉', zip: 524 },
	{ id: 156, cityID: 12, name: '竹塘鄉', zip: 525 },
	{ id: 157, cityID: 12, name: '二林鎮', zip: 526 },
	{ id: 158, cityID: 12, name: '大城鄉', zip: 527 },
	{ id: 159, cityID: 12, name: '芳苑鄉', zip: 528 },
	{ id: 160, cityID: 12, name: '二水鄉', zip: 530 },
	{ id: 161, cityID: 1, name: '南投市', zip: 540 },
	{ id: 162, cityID: 1, name: '中寮鄉', zip: 541 },
	{ id: 163, cityID: 1, name: '草屯鎮', zip: 542 },
	{ id: 164, cityID: 1, name: '國姓鄉', zip: 544 },
	{ id: 165, cityID: 1, name: '埔里鎮', zip: 545 },
	{ id: 166, cityID: 1, name: '仁愛鄉', zip: 546 },
	{ id: 167, cityID: 1, name: '名間鄉', zip: 551 },
	{ id: 168, cityID: 1, name: '集集鎮', zip: 552 },
	{ id: 169, cityID: 1, name: '水里鄉', zip: 553 },
	{ id: 170, cityID: 1, name: '魚池鄉', zip: 555 },
	{ id: 171, cityID: 1, name: '信義鄉', zip: 556 },
	{ id: 172, cityID: 1, name: '竹山鎮', zip: 557 },
	{ id: 173, cityID: 1, name: '鹿谷鄉', zip: 558 },
	{ id: 174, cityID: 22, name: '斗南鎮', zip: 630 },
	{ id: 175, cityID: 22, name: '大埤鄉', zip: 631 },
	{ id: 176, cityID: 22, name: '虎尾鎮', zip: 632 },
	{ id: 177, cityID: 22, name: '土庫鎮', zip: 633 },
	{ id: 178, cityID: 22, name: '褒忠鄉', zip: 634 },
	{ id: 179, cityID: 22, name: '東勢鄉', zip: 635 },
	{ id: 180, cityID: 22, name: '臺西鄉', zip: 636 },
	{ id: 181, cityID: 22, name: '崙背鄉', zip: 637 },
	{ id: 182, cityID: 22, name: '麥寮鄉', zip: 638 },
	{ id: 183, cityID: 22, name: '斗六市', zip: 640 },
	{ id: 184, cityID: 22, name: '林內鄉', zip: 643 },
	{ id: 185, cityID: 22, name: '古坑鄉', zip: 646 },
	{ id: 186, cityID: 22, name: '莿桐鄉', zip: 647 },
	{ id: 187, cityID: 22, name: '西螺鎮', zip: 648 },
	{ id: 188, cityID: 22, name: '二崙鄉', zip: 649 },
	{ id: 189, cityID: 22, name: '北港鎮', zip: 651 },
	{ id: 190, cityID: 22, name: '水林鄉', zip: 652 },
	{ id: 191, cityID: 22, name: '口湖鄉', zip: 653 },
	{ id: 192, cityID: 22, name: '四湖鄉', zip: 654 },
	{ id: 193, cityID: 22, name: '元長鄉', zip: 655 },
	{ id: 194, cityID: 8, name: '番路鄉', zip: 602 },
	{ id: 195, cityID: 8, name: '梅山鄉', zip: 603 },
	{ id: 196, cityID: 8, name: '竹崎鄉', zip: 604 },
	{ id: 197, cityID: 8, name: '阿里山鄉', zip: 605 },
	{ id: 198, cityID: 8, name: '中埔鄉', zip: 606 },
	{ id: 199, cityID: 8, name: '大埔鄉', zip: 607 },
	{ id: 200, cityID: 8, name: '水上鄉', zip: 608 },
	{ id: 201, cityID: 8, name: '鹿草鄉', zip: 611 },
	{ id: 202, cityID: 8, name: '太保市', zip: 612 },
	{ id: 203, cityID: 8, name: '朴子市', zip: 613 },
	{ id: 204, cityID: 8, name: '東石鄉', zip: 614 },
	{ id: 205, cityID: 8, name: '六腳鄉', zip: 615 },
	{ id: 206, cityID: 8, name: '新港鄉', zip: 616 },
	{ id: 207, cityID: 8, name: '民雄鄉', zip: 621 },
	{ id: 208, cityID: 8, name: '大林鎮', zip: 622 },
	{ id: 209, cityID: 8, name: '溪口鄉', zip: 623 },
	{ id: 210, cityID: 8, name: '義竹鄉', zip: 624 },
	{ id: 211, cityID: 8, name: '布袋鎮', zip: 625 },
	{ id: 212, cityID: 5, name: '中西區', zip: 700 },
	{ id: 213, cityID: 5, name: '東區', zip: 701 },
	{ id: 214, cityID: 5, name: '南區', zip: 702 },
	{ id: 215, cityID: 5, name: '北區', zip: 704 },
	{ id: 216, cityID: 5, name: '安平區', zip: 708 },
	{ id: 217, cityID: 5, name: '安南區', zip: 709 },
	{ id: 218, cityID: 5, name: '永康區', zip: 710 },
	{ id: 219, cityID: 5, name: '歸仁區', zip: 711 },
	{ id: 220, cityID: 5, name: '新化區', zip: 712 },
	{ id: 221, cityID: 5, name: '左鎮區', zip: 713 },
	{ id: 222, cityID: 5, name: '玉井區', zip: 714 },
	{ id: 223, cityID: 5, name: '楠西區', zip: 715 },
	{ id: 224, cityID: 5, name: '南化區', zip: 716 },
	{ id: 225, cityID: 5, name: '仁德區', zip: 717 },
	{ id: 226, cityID: 5, name: '關廟區', zip: 718 },
	{ id: 227, cityID: 5, name: '龍崎區', zip: 719 },
	{ id: 228, cityID: 5, name: '官田區', zip: 720 },
	{ id: 229, cityID: 5, name: '麻豆區', zip: 721 },
	{ id: 230, cityID: 5, name: '佳里區', zip: 722 },
	{ id: 231, cityID: 5, name: '西港區', zip: 723 },
	{ id: 232, cityID: 5, name: '七股區', zip: 724 },
	{ id: 233, cityID: 5, name: '將軍區', zip: 725 },
	{ id: 234, cityID: 5, name: '學甲區', zip: 726 },
	{ id: 235, cityID: 5, name: '北門區', zip: 727 },
	{ id: 236, cityID: 5, name: '新營區', zip: 730 },
	{ id: 237, cityID: 5, name: '後壁區', zip: 731 },
	{ id: 238, cityID: 5, name: '白河區', zip: 732 },
	{ id: 239, cityID: 5, name: '東山區', zip: 733 },
	{ id: 240, cityID: 5, name: '六甲區', zip: 734 },
	{ id: 241, cityID: 5, name: '下營區', zip: 735 },
	{ id: 242, cityID: 5, name: '柳營區', zip: 736 },
	{ id: 243, cityID: 5, name: '鹽水區', zip: 737 },
	{ id: 244, cityID: 5, name: '善化區', zip: 741 },
	{ id: 245, cityID: 5, name: '大內區', zip: 742 },
	{ id: 246, cityID: 5, name: '山上區', zip: 743 },
	{ id: 247, cityID: 5, name: '新市區', zip: 744 },
	{ id: 248, cityID: 5, name: '安定區', zip: 745 },
	{ id: 249, cityID: 23, name: '新興區', zip: 800 },
	{ id: 250, cityID: 23, name: '前金區', zip: 801 },
	{ id: 251, cityID: 23, name: '苓雅區', zip: 802 },
	{ id: 252, cityID: 23, name: '鹽埕區', zip: 803 },
	{ id: 253, cityID: 23, name: '鼓山區', zip: 804 },
	{ id: 254, cityID: 23, name: '旗津區', zip: 805 },
	{ id: 255, cityID: 23, name: '前鎮區', zip: 806 },
	{ id: 256, cityID: 23, name: '三民區', zip: 807 },
	{ id: 257, cityID: 23, name: '楠梓區', zip: 811 },
	{ id: 258, cityID: 23, name: '小港區', zip: 812 },
	{ id: 259, cityID: 23, name: '左營區', zip: 813 },
	{ id: 260, cityID: 23, name: '仁武區', zip: 814 },
	{ id: 261, cityID: 23, name: '大社區', zip: 815 },
	{ id: 262, cityID: 23, name: '岡山區', zip: 820 },
	{ id: 263, cityID: 23, name: '路竹區', zip: 821 },
	{ id: 264, cityID: 23, name: '阿蓮區', zip: 822 },
	{ id: 265, cityID: 23, name: '田寮區', zip: 823 },
	{ id: 266, cityID: 23, name: '燕巢區', zip: 824 },
	{ id: 267, cityID: 23, name: '橋頭區', zip: 825 },
	{ id: 268, cityID: 23, name: '梓官區', zip: 826 },
	{ id: 269, cityID: 23, name: '彌陀區', zip: 827 },
	{ id: 270, cityID: 23, name: '永安區', zip: 828 },
	{ id: 271, cityID: 23, name: '湖內區', zip: 829 },
	{ id: 272, cityID: 23, name: '鳳山區', zip: 830 },
	{ id: 273, cityID: 23, name: '大寮區', zip: 831 },
	{ id: 274, cityID: 23, name: '林園區', zip: 832 },
	{ id: 275, cityID: 23, name: '鳥松區', zip: 833 },
	{ id: 276, cityID: 23, name: '大樹區', zip: 840 },
	{ id: 277, cityID: 23, name: '旗山區', zip: 842 },
	{ id: 278, cityID: 23, name: '美濃區', zip: 843 },
	{ id: 279, cityID: 23, name: '六龜區', zip: 844 },
	{ id: 280, cityID: 23, name: '內門區', zip: 845 },
	{ id: 281, cityID: 23, name: '杉林區', zip: 846 },
	{ id: 282, cityID: 23, name: '甲仙區', zip: 847 },
	{ id: 283, cityID: 23, name: '桃源區', zip: 848 },
	{ id: 284, cityID: 23, name: '那瑪夏區', zip: 849 },
	{ id: 285, cityID: 23, name: '茂林區', zip: 851 },
	{ id: 286, cityID: 23, name: '茄萣區', zip: 852 },
	{ id: 287, cityID: 2, name: '東沙', zip: 817 },
	{ id: 288, cityID: 2, name: '南沙', zip: 819 },
	{ id: 289, cityID: 17, name: '馬公市', zip: 880 },
	{ id: 290, cityID: 17, name: '西嶼鄉', zip: 881 },
	{ id: 291, cityID: 17, name: '望安鄉', zip: 882 },
	{ id: 292, cityID: 17, name: '七美鄉', zip: 883 },
	{ id: 293, cityID: 17, name: '白沙鄉', zip: 884 },
	{ id: 294, cityID: 17, name: '湖西鄉', zip: 885 },
	{ id: 295, cityID: 11, name: '屏東市', zip: 900 },
	{ id: 296, cityID: 11, name: '三地門鄉', zip: 901 },
	{ id: 297, cityID: 11, name: '霧臺鄉', zip: 902 },
	{ id: 298, cityID: 11, name: '瑪家鄉', zip: 903 },
	{ id: 299, cityID: 11, name: '九如鄉', zip: 904 },
	{ id: 300, cityID: 11, name: '里港鄉', zip: 905 },
	{ id: 301, cityID: 11, name: '高樹鄉', zip: 906 },
	{ id: 302, cityID: 11, name: '鹽埔鄉', zip: 907 },
	{ id: 303, cityID: 11, name: '長治鄉', zip: 908 },
	{ id: 304, cityID: 11, name: '麟洛鄉', zip: 909 },
	{ id: 305, cityID: 11, name: '竹田鄉', zip: 911 },
	{ id: 306, cityID: 11, name: '內埔鄉', zip: 912 },
	{ id: 307, cityID: 11, name: '萬丹鄉', zip: 913 },
	{ id: 308, cityID: 11, name: '潮州鎮', zip: 920 },
	{ id: 309, cityID: 11, name: '泰武鄉', zip: 921 },
	{ id: 310, cityID: 11, name: '來義鄉', zip: 922 },
	{ id: 311, cityID: 11, name: '萬巒鄉', zip: 923 },
	{ id: 312, cityID: 11, name: '崁頂鄉', zip: 924 },
	{ id: 313, cityID: 11, name: '新埤鄉', zip: 925 },
	{ id: 314, cityID: 11, name: '南州鄉', zip: 926 },
	{ id: 315, cityID: 11, name: '林邊鄉', zip: 927 },
	{ id: 316, cityID: 11, name: '東港鄉', zip: 928 },
	{ id: 316, cityID: 11, name: '東港鎮', zip: 928 },
	{ id: 317, cityID: 11, name: '琉球鄉', zip: 929 },
	{ id: 318, cityID: 11, name: '佳冬鄉', zip: 931 },
	{ id: 319, cityID: 11, name: '新園鄉', zip: 932 },
	{ id: 320, cityID: 11, name: '枋寮鄉', zip: 940 },
	{ id: 321, cityID: 11, name: '枋山鄉', zip: 941 },
	{ id: 322, cityID: 11, name: '春日鄉', zip: 942 },
	{ id: 323, cityID: 11, name: '獅子鄉', zip: 943 },
	{ id: 324, cityID: 11, name: '車城鄉', zip: 944 },
	{ id: 325, cityID: 11, name: '牡丹鄉', zip: 945 },
	{ id: 326, cityID: 11, name: '恆春鄉', zip: 946 },
	{ id: 326, cityID: 11, name: '恆春鎮', zip: 946 },
	{ id: 327, cityID: 11, name: '滿州鄉', zip: 947 },
	{ id: 328, cityID: 6, name: '臺東市', zip: 950 },
	{ id: 329, cityID: 6, name: '綠島鄉', zip: 951 },
	{ id: 330, cityID: 6, name: '蘭嶼鄉', zip: 952 },
	{ id: 331, cityID: 6, name: '延平鄉', zip: 953 },
	{ id: 332, cityID: 6, name: '卑南鄉', zip: 954 },
	{ id: 333, cityID: 6, name: '鹿野鄉', zip: 955 },
	{ id: 334, cityID: 6, name: '關山鎮', zip: 956 },
	{ id: 335, cityID: 6, name: '海端鄉', zip: 957 },
	{ id: 336, cityID: 6, name: '池上鄉', zip: 958 },
	{ id: 337, cityID: 6, name: '東河鄉', zip: 959 },
	{ id: 338, cityID: 6, name: '成功鎮', zip: 961 },
	{ id: 339, cityID: 6, name: '長濱鄉', zip: 962 },
	{ id: 340, cityID: 6, name: '太麻里鄉', zip: 963 },
	{ id: 341, cityID: 6, name: '金峰鄉', zip: 964 },
	{ id: 342, cityID: 6, name: '大武鄉', zip: 965 },
	{ id: 343, cityID: 6, name: '達仁鄉', zip: 966 },
	{ id: 344, cityID: 18, name: '花蓮市', zip: 970 },
	{ id: 345, cityID: 18, name: '新城鄉', zip: 971 },
	{ id: 346, cityID: 18, name: '秀林鄉', zip: 972 },
	{ id: 347, cityID: 18, name: '吉安鄉', zip: 973 },
	{ id: 348, cityID: 18, name: '壽豐鄉', zip: 974 },
	{ id: 349, cityID: 18, name: '鳳林鎮', zip: 975 },
	{ id: 350, cityID: 18, name: '光復鄉', zip: 976 },
	{ id: 351, cityID: 18, name: '豐濱鄉', zip: 977 },
	{ id: 352, cityID: 18, name: '瑞穗鄉', zip: 978 },
	{ id: 353, cityID: 18, name: '萬榮鄉', zip: 979 },
	{ id: 354, cityID: 18, name: '玉里鎮', zip: 981 },
	{ id: 355, cityID: 18, name: '卓溪鄉', zip: 982 },
	{ id: 356, cityID: 18, name: '富里鄉', zip: 983 },
	{ id: 357, cityID: 21, name: '金沙鎮', zip: 890 },
	{ id: 358, cityID: 21, name: '金湖鎮', zip: 891 },
	{ id: 359, cityID: 21, name: '金寧鄉', zip: 892 },
	{ id: 360, cityID: 21, name: '金城鎮', zip: 893 },
	{ id: 361, cityID: 21, name: '烈嶼鄉', zip: 894 },
	{ id: 362, cityID: 21, name: '烏坵鄉', zip: 896 },
	{ id: 363, cityID: 20, name: '南竿鄉', zip: 209 },
	{ id: 364, cityID: 20, name: '北竿鄉', zip: 210 },
	{ id: 365, cityID: 20, name: '莒光鄉', zip: 211 },
	{ id: 366, cityID: 20, name: '東引鄉', zip: 212 },

	{ id: 367, cityID: 7, name: '嘉義市', zip: 600 },
	{ id: 368, cityID: 14, name: '新竹市', zip: 300 },

	{ id: 369, cityID: 14, name: '東區', zip: 300 },
	{ id: 370, cityID: 14, name: '北區', zip: 300 },
	{ id: 371, cityID: 14, name: '香山區', zip: 300 },
	{ id: 372, cityID: 7, name: '東區', zip: 600 },
	{ id: 373, cityID: 7, name: '西區', zip: 600 },
];

export default items;
